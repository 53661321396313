<template>
  <div class="project-details">
    <b-container fluid>
      <section v-if="project.media">
        <thumbs-gallery
          class="project-details__carasoul"
          :conf-photos="project.media"
        />
      </section>
      <section v-else-if="requestChecker" class="py-5 px-2">
        <content-placeholders :rounded="true" :centered="true">
          <content-placeholders-img style="height: 500px" />
          <section class="d-flex justify-content-between">
            <content-placeholders-img
              v-for="index in 3"
              :key="index"
              style="height: 100px; width: 32%; margin: 20px 0 0"
            />
          </section>
        </content-placeholders>
      </section>

      <b-container class="project-details__container">
        <b-row no-gutters>
          <b-col class="d-flex mb-5" cols="12">
            <Button
              v-if="project.demos.length > 0"
              class="button button--filled button--md"
              @click="toggleDemosOverlay"
              :text="$t('button.demo')"
              :hasIcon="false"
              variant="outline-light"
            ></Button>
            <Button
              class="button button--filled mx-3"
              @click="toggleProjectOverlay"
              :text="$t('button.start_project')"
              :hasIcon="false"
              variant="outline-light"
            ></Button>
          </b-col>
          <b-col lg="12">
            <h1 class="advantages-list__title" v-text="project.name" />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="8" class="mb-5">
            <p
              class="main-description main-description--paragraph mt-5"
              v-text="project.description"
            ></p>
            <advantages-list class="mt-5" :list-data="features" />
          </b-col>

          <!-- PLATFORMS -->

          <b-col lg="4" class="mt-5">
            <section
              v-if="project.platforms && project.platforms.length > 0"
              class="project-details__thumb-group"
            >
              <h4 class="project-details__thumb-group__title">
                {{ $t("heading.available_platforms") }}
              </h4>
              <Platforms :platforms="project.platforms" />
            </section>

            <!-- TECHNOLOGIES -->

            <section
              v-if="project.technologies && project.technologies.length > 0"
              class="project-details__thumb-group"
            >
              <h4 class="project-details__thumb-group__title">
                {{ $t("heading.used_technologies") }}
              </h4>
              <technologies :technologies="project.technologies" />
            </section>

            <!-- TAGS -->

            <section class="project-details__thumb-group">
              <h4 class="project-details__thumb-group__title">
                {{ $t("heading.project_tags") }}
              </h4>

              <b-badge
                v-for="tag in project.categories"
                :key="tag.id"
                :style="{ backgroundColor: tag.color }"
                class="tag"
              >
                <b-img :src="tag.icon.path" rounded="circle" />
                {{ tag.name }}</b-badge
              >
            </section>
          </b-col>
        </b-row>
      </b-container>

      <project />
    </b-container>
  </div>
</template>

<script>
import { ShowData } from "@/helpers/apiMethods";

export default {
  name: "ProjectDetails",
  metaInfo() {
    return {
      title: this.project.name,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.project.name
        },
        {
          vmid: "description",
          name: "description",
          content: this.project.description
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${this.project.name} متجر سولوشن بلص`
        }
      ]
    };
  },
  components: {
    ThumbsGallery: () => import("@/components/ThumbsGallery"),
    AdvantagesList: () => import("@/components/AdvantagesList"),
    Project: () => import("@/components/Project"),
    Platforms: () => import("@/components/Platforms"),
    Technologies: () => import("@/components/Technologies")
  },

  data() {
    return {
      project: {},
      demoOptionsOverlay: false,
      orderOverlay: false,
      requestChecker: true
    };
  },

  computed: {
    slug() {
      return this.$route.params.slug;
    },

    features() {
      return {
        title: "features",
        items: this.project.features && this.project.features
      };
    }
  },
  mounted() {
    // to remove chemmer if there is no data
    setTimeout(() => {
      this.requestChecker = false;
    }, 10000);
  },
  created() {
    this.fetchProject();
  },

  methods: {
    fetchProject() {
      ShowData({ reqName: "projects", id: this.slug }).then(res => {
        this.project = res.data.project;
        this.project.media.length > 0 &&
          this.project.media.forEach(
            (el, i) => el.main === true && this.project.media.splice(i, 1)
          );
      });
    },

    toggleDemosOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "project",
        isOpen: true,
        typeIndex: 1,
        demos: this.project.demos
      });
    },

    toggleProjectOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        projectId: this.project.id,
        type: "project",
        isOpen: true
      });
    }
  }
};
</script>
